<template>
  <div>
    <v-container fluid>

      <div class="main">
        <v-row>
          <v-col cols="12">

            <v-card class="rounded-lg shadow">

              <v-card-title>
                Dashboard recouvrements
                <v-spacer/>
                <v-btn depressed
                       exact
                       fab
                       small
                       to="/recoveries">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-card-title>

              <v-divider/>


              <v-card-text>

                <v-row>

                  <v-col cols="12" lg="3">
                    <v-card outlined>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <h3 class="mb-2">Pos</h3>
                            <div class="font-weight-medium">
                              {{ salepoints.length }}
                            </div>
                          </v-col>
                          <v-col class="text-right">
                            <v-avatar color="gifty">
                              <v-icon dark>mdi-account-group-outline</v-icon>
                            </v-avatar>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col cols="12">
                            <span>Total points de vente</span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" lg="3">
                    <v-card outlined>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <h3 class="mb-2">DZD</h3>
                            <div class="font-weight-medium">
                              {{ CurrencyFormatting(totalReffil()) }}
                            </div>
                          </v-col>
                          <v-col class="text-right">
                            <v-avatar color="primary">
                              <v-icon dark>mdi-currency-usd</v-icon>
                            </v-avatar>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col cols="12">
                            <span>Total solde actuel</span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-card outlined>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <h3 class="mb-2">DZD</h3>
                            <div class="font-weight-medium">
                              {{ CurrencyFormatting(total()) }}
                            </div>
                          </v-col>
                          <v-col class="text-right">
                            <v-avatar color="primary">
                              <v-icon dark>mdi-currency-usd</v-icon>
                            </v-avatar>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col cols="12">
                            <span>Total reste à récupérer</span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                  </v-col>

                  <v-col cols="12">

                    <v-row align="center">
                      <v-col cols="8">
                        <div class="d-flex align-center">
                          <v-text-field placeholder="Recherche"
                                        clearable
                                        dense
                                        v-model="search"
                                        hide-details
                                        outlined
                                        append-icon="mdi-magnify"
                          ></v-text-field>
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          <v-autocomplete label="Service"
                                          clearable
                                          multiple
                                          chips
                                          small-chips
                                          deletable-chips
                                          dense
                                          :items="services"
                                          v-model="service_ids"
                                          hide-details
                                          outlined
                                          prepend-icon="mdi-filter"
                                          item-value="id"
                                          item-text="name"
                          ></v-autocomplete>
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          <v-autocomplete label="Fournisseur"
                                          clearable
                                          dense
                                          multiple
                                          chips
                                          small-chips
                                          deletable-chips
                                          :items="suppliers"
                                          hide-details
                                          v-model="tenant_ids"
                                          outlined
                                          prepend-icon="mdi-filter"
                                          item-value="id"
                                          item-text="name"
                          ></v-autocomplete>

                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="text-end">
                          <v-btn color="gifty"
                                 dark
                                 text
                                 @click="getRecoveriesDashboard">
                            <v-icon left>mdi-refresh</v-icon>
                            Mettre à jour
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>

                    <v-data-table class="table-border rounded mt-3"
                                  :expanded.sync="expanded"
                                  :search="search"
                                  show-expand
                                  :loading="isLoading"
                                  :headers="headers"
                                  :items="salepoints"
                    >

                      <template v-slot:item.data-table-expand="{item, isExpanded}">
                        <v-icon color="gifty"
                                @click="handleExpansion(item, isExpanded)"
                        >{{ !isExpanded ? 'mdi-plus-circle-outline' : 'mdi-minus-circle-outline' }}
                        </v-icon>
                      </template>


                      <template v-slot:item.refill_amount="{ item }">
                        <div class="font-weight-medium gifty--text text-no-wrap">
                          {{ CurrencyFormatting(item.refill_amount) }} DZD
                        </div>
                      </template>

                      <template v-slot:item.remaining_amount="{ item }">
                        <div class="font-weight-medium primary--text text-no-wrap">
                          {{ CurrencyFormatting(item.remaining_amount) }} DZD
                        </div>
                      </template>

                      <template v-slot:item.pos_name="{ item }">
                        <div class="font-weight-medium">
                          {{ item.pos_name }}
                        </div>
                      </template>

                      <template v-slot:item.latest_recovery="{ item }">
                        <div class="font-weight-medium" v-if="item.latest_recovery">
                          {{ dateFormat(item.latest_recovery.recovery_at) }}
                        </div>
                        <div v-else>
                          -
                        </div>
                      </template>

                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="bg-light">

                          <div class="pa-12">

                            <v-card outlined v-if="item.latest_recovery">
                              <v-card-title>
                                 <span class="f-14">
                                   Détails de dernière recouvrement
                                 </span>
                              </v-card-title>

                              <v-divider/>

                              <v-card-text class="pa-0">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead>
                                    <tr>
                                      <th>Recouvreur</th>
                                      <th>service</th>
                                      <th>Montant</th>
                                      <th>Source</th>
                                      <th>Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                      <td class="font-weight-medium font-weight-medium">
                                        {{ item.latest_recovery.user.name }}
                                      </td>
                                      <td>{{ item.latest_recovery.service.name }}</td>
                                      <td>
                                        <div class="font-weight-medium primary--text text-no-wrap">
                                          {{ CurrencyFormatting(item.latest_recovery.paid_amount) }} DZD
                                        </div>
                                      </td>
                                      <td>{{ item.latest_recovery.source }}</td>
                                      <td>
                                        {{ dateFormat(item.latest_recovery.recovery_at) }}
                                      </td>
                                    </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>

                              </v-card-text>
                            </v-card>


                            <v-card outlined class="mt-3">
                              <v-card-title>
                                 <span class="f-14">
                                  Services
                                 </span>
                              </v-card-title>

                              <v-divider/>

                              <v-card-text class="pa-0">

                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead>
                                    <tr>
                                      <th>Service</th>
                                      <th>Solde actuel</th>
                                      <th>Reste à récupérer</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(service,i) in item.services"
                                        :key="i"
                                    >
                                      <td class="font-weight-medium">{{ service.name }}</td>
                                      <td>
                                        <div class="font-weight-medium gifty--text text-no-wrap">
                                          {{ CurrencyFormatting(service.refill_amount) }} DZD
                                        </div>
                                      </td>
                                      <td>
                                        <div class="font-weight-medium primary--text text-no-wrap">
                                          {{ CurrencyFormatting(service.remaining_amount) }} DZD
                                        </div>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>

                              </v-card-text>
                            </v-card>

                            <v-card outlined class="mt-3">
                              <v-card-title>
                                 <span class="f-14">
                                  Recouvreurs
                                 </span>
                              </v-card-title>

                              <v-divider/>

                              <v-card-text class="pa-0">

                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead>
                                    <tr>
                                      <th>Nom complet</th>
                                      <th>Email</th>
                                      <th>Téléphone</th>
                                      <th>Reste à récupérer</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(recuperator,i) in item.recuperators"
                                        :key="i"
                                    >
                                      <td class="font-weight-medium">{{ recuperator.name }}</td>
                                      <td>{{ recuperator.email }}</td>
                                      <td>{{ recuperator.phone }}</td>
                                      <td>
                                        <div class="font-weight-medium primary--text text-no-wrap">
                                          {{ CurrencyFormatting(recuperator.remaining_amount) }} DZD
                                        </div>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>

                              </v-card-text>
                            </v-card>


                          </div>

                        </td>
                      </template>

                    </v-data-table>

                  </v-col>

                </v-row>

              </v-card-text>

            </v-card>
          </v-col>

        </v-row>
      </div>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import moment from "moment";

export default {
  data() {
    return {
      search: null,
      isLoading: false,
      salepoints: [],
      expanded: [],

      services: [],
      suppliers: [],

      headers: [
        {text: 'Nom de pos', value: 'pos_name'},
        {text: 'Type', value: 'type'},
        {text: 'Wilaya', value: 'wilaya.name'},
        {text: 'Commune', value: 'commune.name'},
        {text: 'Crédit', value: 'refill_amount'},
        {text: 'Reste à récupérer', value: 'remaining_amount'},
        {text: 'Date de dernière recouvrement', value: 'latest_recovery'},
        {text: '', value: 'data-table-expand'},
      ],

      service_ids: [],
      tenant_ids: [],
    };
  },
  methods: {
    getRecoveriesDashboard() {
      this.isLoading = true
      HTTP.get('/v1/recoveries/dashboard-data', {
        params: {
          service_ids: this.service_ids,
          tenant_ids: this.tenant_ids
        }
      }).then(res => {
        this.isLoading = false
        this.salepoints = res.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    total() {
      return this.salepoints.reduce((sum, salepoint) => sum + salepoint.remaining_amount, 0);
    },
    totalReffil() {
      return this.salepoints.reduce((sum, salepoint) => sum + salepoint.refill_amount, 0);
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY, HH:mm');
    },
    getRecoveriesDashboardConfig() {
      HTTP.get('/v1/recoveries/dashboard-config').then(res => {
        this.services = res.data.data.services
        this.suppliers = res.data.data.suppliers
      })
    },
  },
  watch: {
    service_ids() {
      this.getRecoveriesDashboard('watch')
    },
    tenant_ids() {
      this.getRecoveriesDashboard('watch')
    },
  },
  created() {
    this.getRecoveriesDashboard()
    this.getRecoveriesDashboardConfig()
  }
};
</script>

<style scoped></style>
